import React, {  useState } from 'react'
import { olish } from '../../../api/services/AuthService'

export default function Looks() {
    const [message, setMessage] = useState('')
    const[foyd, setFoyd] = useState([])
    const[word, setWord] = useState('')

    const olmoq = async() => {
        try {
            const res = await olish(word) 
            console.log(res);
            if(res.status === 400){
                setMessage(res.data.message)
            }
            setFoyd(res.data.users)
        } catch (error) {
            console.error(error);
        }

    }
  return (
    <div className='w-screen h-screen bg-bg'> 
    <input type="text" onChange={e => setWord(e.target.value)} />
    <button className='border p-5 bg-blue-600' onClick={() => olmoq()}>Users</button>
        <h1>{message}</h1>
    <div className='w-screen h-auto bg-bg'>
        {
            foyd.map((foy,index)=> 
                <div className='lg:w-[40%] sm:w-[100%] h-auto flex border-white border'>  
                    <h2 className='text-white border font-bold mr-3 px-3'>{index}</h2>
                     <h2 className='text-white font-bold mr-3'>{foy.name}</h2>
                    <h2 className='text-white font-bold'>{foy.surname}</h2>
                </div>
            )
        }
    </div>
      
    </div>
  )
}
