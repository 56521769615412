import React, { useEffect } from 'react'
import Navbar from '../components/navbar/Navbar'
import HpStatistic from '../components/UI/statistics/HpStatistic'
import '../styles/animation.css'
import Slider from '../components/UI/slider/Slider'
import Main from '../components/main/Main'
import Footer from '../components/footer/Footer'
import { getSevendays } from '../atom/salary'
import { useRecoilState } from 'recoil'
import { getSevendaySalary } from '../api/services/SalaryService'
export default function Home() {

  const[salary, setSalary] = useRecoilState(getSevendays)

	const getSevendaysFunc = async () =>{
		try {
			const res = await getSevendaySalary()
			if(res.status === 400){
			 return  setSalary( [
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				{ x: new Date("0000-00-00"), y:0},
				
			  ])
			}
			const data = res.data.salary
			const newDate = data.map(sal => {
        const [day, month, year] = sal.date.split('.')
        return {
          x: new Date(`${year}-${month}-${day}`),
          y: sal.salary
        }
      } )

			setSalary(newDate)
			
		} catch (error) {
		console.error("Произошла ошибка:", error);
		}
	}

  useEffect(() =>{
    getSevendaysFunc()
  }, [])

  return (
    <div className='h-screen w-[100%] bg-bg box-border'>
      <Navbar/>
      <div className='lg:h-[73%] w-[100%] flex items-center flex-col pt-[2%] sm:h-[33%] '>
        <Slider/>
        <h1 className='uppercase text-white font-bold lg:text-[200%] '>Типы поиска</h1>
      </div>
      <Main/>
      <div className='bg-bg lg:h-[65%] sm:h-[60%] flex justify-center'>
        <HpStatistic/>
      </div>
      <div className='bg-bg'>
        <Footer/>
      </div>
    </div>
  )
}
