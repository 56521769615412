import React, { useState } from 'react'
import '../../../styles/animation.css'
import icons from "../button/icons";
import img_heder1 from '../img/fristHeader.png'
import img_heder2 from '../img/secondHeader.png'
import img_heder3 from '../img/thirdHeader.png'
import Login from '../modal/Login'
import AddModal from '../modal/AddModal'
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isAuth } from '../../../atom';


export default function Slider() {
  const auth = useRecoilValue(isAuth)
  const [modal, setModal] = useState(false)
  const [log, setLog] = useState(false)
  
  return (
    <div className='lg:w-[86%] lg:h-[90%] lg:mt-[0%] sm:mt-[5%] sm:w-[90%] sm:h-[100%] mb-7'>
      <AddModal values={modal} setVisible={setModal} />
      <Login values={log} setLogin={setLog} />
      <div className="slider-box">
        <section className="img-slider">
          <div className="slider-container">

            <div className="slide">
              <div className="slide_img1">
                
                <div className='flex lg:w-[100%] lg:h-[100%] '>
                  <div className='relative lg:w-[50%] h-[100%] flex flex-col items-center lg:mt-0 sm:w-[50%] sm:left-[1%]  sm:mt-[-1%]'>
                    <h1 className="sm:text-[210%] font-bold lg:text-8xl text-white lg:mt-[10%] sm:mt-[10%]">
                      Salary <span className="font-bold text-secondary">Calc</span>
                    </h1>
                    <h2 className=' relative lg:mt-[5%] text-white lg:text-[120%] xl:left-[-5%] lg:left-[1%] sm:text-[90%] sm:left-[2%] '>Возможность рассчитывать и сохранять свою зарплату</h2>
                    <div className="flex justify-between lg:w-[50%] relative xl:right-[5%] lg:right-[14%] lg:mt-[8%] sm:w-[80%] sm:mt-[10%] sm:right-[8%]">
                      <Link to="/info">{icons.info_btn}</Link>
                    </div>
                  </div>
                  <img className='relative lg:left-[2%] lg:w-[45%] h-[95%] lg:mt-[1%] sm:w-[40%] sm:h-[85%] sm:mt-[4%] sm:left-[6%]' src={img_heder1} alt="heder1" />
                </div>

              </div>
            </div>
            <div className="slide">
              <div className='slide_img2' alt="">

                <div className='flex lg:w-[100%] lg:h-[100%] '>
                  <div className='relative lg:w-[50%] h-[100%] flex flex-col items-center lg:mt-0 sm:w-[50%] sm:left-[3%]  sm:mt-[3%]'>
                    <h1 className="sm:text-[210%] font-bold lg:text-8xl text-white lg:mt-[10%] sm:mt-[10%]">
                      Salary <span className="font-bold text-secondary">Calc</span>
                    </h1>
                    <h2 className=' relative lg:mt-[5%] text-white xl:left-[-5%] lg:text-[120%] lg:left-[-7%] sm:text-[90%] sm:left-[2%] '>Пройдите авторизацию и начните считать</h2>
                    <div className="flex justify-between xl:w-[35%] lg:w-[47%] relative xl:right-[12.5%] lg:right-[15%] lg:mt-[8%] sm:w-[90%] sm:mt-[6%] sm:right-[7%]">
                    <Link to='/registration' className=' lg:mr-0 sm:mr-4 sm:w-[70%]'>{icons.register_btn}</Link>
                      {auth ? ('') : (<button onClick={() => setLog(true)} className='w-[50%]'>{icons.head_auth_btn}</button>)}
                    </div>
                  </div>
                  <img className='relative lg: left-[2%] lg:w-[45%] h-[95%] lg:mt-[1%] sm:w-[43%] sm:h-[92%] sm:mt-[14%] sm:left-[8%]' src={img_heder2} alt="heder1" />
                </div>

              </div>
            </div>
            <div className="slide">
              <div className='slide_img3' alt="">

                <div className='flex lg:w-[100%] lg:h-[100%] '>
                  <div className='relative lg:w-[50%] h-[100%] flex flex-col items-center lg:mt-0 sm:w-[50%] sm:left-[3%]  sm:mt-[6%]'>
                    <h1 className="sm:text-[210%] font-bold lg:text-8xl text-white lg:mt-[10%] sm:mt-[10%]">
                      Salary <span className="font-bold text-secondary">Calc</span>
                    </h1>
                    <h2 className=' relative lg:mt-[5%] text-white xl:left-[-16%] lg:text-[120%] lg:left-[-21%] sm:text-[90%] sm:left-[-2%] '>Для вопросов и запросов</h2>
                    <div className="flex justify-between xl:w-[40%] lg:w-[55%] relative xl:right-[10%] lg:right-[11%] lg:mt-[8%] sm:w-[95%] sm:mt-[10%] sm:right-[2%]">
                      <a href="https://t.me/isnaddinov01" target='_blank'>{icons.telegram_btn}</a>
                      <a href="https://www.instagram.com/isnaddinovotabek?igsh=OGczMHhtc20wM2pv" target='_blank'>{icons.instagram_btn}</a>
                    </div>
                  </div>
                  <img className='relative lg:left-[2%] lg:w-[45%] h-[95%] lg:mt-[1%] sm:w-[45%] sm:h-[90%] sm:mt-[12%] sm:left-[5%]' src={img_heder3} alt="heder1" />
                </div>
                
              </div>
            </div>

          </div>
        </section>

      </div>
    </div>
  )
}
