import $api from "../http"


export async function postSalary(salary){
    try {
        
        return await $api.post('/calc/post', salary)

    } catch (error) {
        return error.response
    }
}

export async function pustSalary(id, salary){
    try {
        return await $api.put(`/calc/put/${id}`, salary)

    } catch (error) {
        return error.response
    }
}

export async function removeSalary(id){
    try {
        return await $api.delete(`/calc/delete/${id}`)

    } catch (error) {
        return error.response
    }
}

export async function getAllSalary(){
    try {
        return await $api.get(`/calc/get`)

    } catch (error) {
        return error.response
    }
}

export async function getDateSalary(date){
    try {
        return await $api.get(`/calc/getbydate/${date}`)

    } catch (error) {
        return error.response
    }
}

export async function getByLifTimeSalary(gte, lte){
    try {
        return await $api.get(`/calc/getbylifetime/${gte}-${lte}`)

    } catch (error) {
        return error.response
    }
}

export async function getForMonthSalary(month, year){
    try {
        return await $api.get(`/calc/getformonth/${month}-${year}`)

    } catch (error) {
        return error.response
    }
}

export async function getSevendaySalary(){
    try {
        return await $api.get(`/calc/getbysevendays`)

    } catch (error) {
        return error.response
    }
}



