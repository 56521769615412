import React, {useState} from 'react'
import add2 from '../icons/add2.png'
import { getSector, putSector } from '../../../api/services/SectorService'
import { useRecoilState } from 'recoil'
import { isAlert, isSectorPutAlert, sectorMessage } from '../../../atom'

export default function SecPayModal({visible, setVisible }) {
    let on = visible

    const [alert, setAlert] = useRecoilState(isAlert)
    const [sectorPutAlert, setSectorPutAlert] = useRecoilState(isSectorPutAlert)



    const [sectorData, setSectorData] = useState({
        os: undefined,
        osmulti: undefined, 
        zam: undefined,
        zammulti: undefined, 
        oxl: undefined,
        oxlmulti: undefined, 
        fresh: undefined,
        freshmulti: undefined,
        oskam: undefined,
        oskammulti: undefined
    })

    const [secMessage, setSecMessage] = useRecoilState(sectorMessage)

    const sectorChange = (e) => {
        setSectorData((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
        }))
    }

    const updateSector = async () => {
        try {
            const res = await putSector(sectorData)
            
            if(res.status === 400){
               return setSecMessage(res.data.message)
            }

            setVisible(false)
            setAlert(true)
            setSectorPutAlert(true)
            setSecMessage('')
            
            setTimeout(() => {
              setSectorPutAlert(false)
              setAlert(false)
            }, 3000);

        } catch (error) {
            console.error("Произошла ошибка:", error);
            setSecMessage('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
        
    }

  return (
   on ?  (<div onClick={() => {setVisible(false)}} className='fixed flex justify-center items-center top-0 bottom-0 right-0 left-0 w-screen h-screen z-10 bg-black/70'>
        <div className='flex justify-center sm:relative sm:top-[2.5%] sm:w-[70%] sm:h-[80%] sm:items-center lg:flex-wrap flex-col lg:gap-y-4 sm:gap-[0.5%] lg:relative  rounded-lg lg:w-1/2 lg:h-[55%] bg-[#0E0E2C] pt-[1%]' onClick={(e) => e.stopPropagation()}>

<div className="inputBox">
    <input  type="number" name='os'  onChange={e => sectorChange(e)}/>
    <span >Основной</span>
</div>

<div className="inputBox">
    <input  type="number" name='osmulti' onChange={e => sectorChange(e)} />
    <span >ОСМульти</span>
</div>
<div className="inputBox">
    <input  type="number" name='oxl'  onChange={e => sectorChange(e)} />
    <span >Охлождёнка</span>
</div>
<div className="inputBox">
    <input  type="number" name='oxlmulti'  onChange={e => sectorChange(e)} />
    <span >ОхлМульти</span>
</div>
<div className="inputBox">
    <input  type="number" name='zam'  onChange={e => sectorChange(e)} />
    <span >Заморозка</span>
</div>
<div className="inputBox">
    <input  type="number" name='zammulti' onChange={e => sectorChange(e)} />
    <span >ЗамМульти</span>
</div>
<div className="inputBox">
    <input  type="number" name='fresh' onChange={e => sectorChange(e)} />
    <span >Фреш</span>
</div> 
<div className="inputBox">
    <input  type="number" name='freshmulti' onChange={e => sectorChange(e)} />
    <span >ФрешМульти</span>
</div>
<div className="inputBox">
    <input  type="number" name='oskam'  onChange={e => sectorChange(e)} />
    <span >Оскамера</span>
</div>
<div className="inputBox">
    <input  type="number" name='oskammulti'  onChange={e => sectorChange(e)} />
    <span >КамМульти</span>
</div>
<div className='w-[30%] h-[20%]'>
<h1 className='text-white lg:ml-0 lg:text-[120%] sm:ml-3 sm:text-[100%]'>Введите сумму, которую платит за каждый сектор!</h1>
</div>
<div onClick={() => updateSector()} className=" flex justify-between items-center transition ease-out border-none rounded-full border   font-medium text-white bg-primary cursor-pointer lg:hover:scale-110  lg:active:scale-125  lg:w-[130px] lg:h-[46px]  lg:text-base  lg:py-2 lg:px-5 sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[13%]  sm:text-[100%] sm:hover:scale-110  sm:active:scale-125"><img className='lg:w-[30px] lg:h-[28px] sm:w-[30%] sm:h-[75%] ' src={add2}/>Secotor</div>
{secMessage && <p className='relative  text-red-700 text-sm'>{secMessage}</p>}
</div>
    </div>) : ('')
  )
}
