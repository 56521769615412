import React, { useEffect } from "react";
import AppRoutes from "./routes/AppRoutes";
import { useRecoilState } from "recoil";
import { isAuth, userInfo, isSecpay } from "./atom";
import { checkAuth } from "./api/services/AuthService";
import { getSector } from "./api/services/SectorService";
import { Cookie } from "@mui/icons-material";


function App() {
  const [auth, setAuth] = useRecoilState(isAuth);
  const [user, setUser] = useRecoilState(userInfo);
  const [sector, setSector] = useRecoilState(isSecpay);

  useEffect(() => {
    
    const checkAccount = async () => {
      try {
        if (localStorage.getItem("token")) {
          const res = await checkAuth();
          if (res.status === 401) {
            localStorage.removeItem("token");
            setAuth(false);
          } else {
            setUser(res.data.info);
            localStorage.setItem("token", res.data.accessToken);
            setAuth(true);
          }
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkAccount();
  }, []);

  useEffect(() => {
    const isSectorPay = async () => {
      const res = await getSector();
      if (res.data.secPay != undefined || null) {
        setSector(true);
      }
    };
    isSectorPay();
  }, []);

  return (
    <div className="w-[100%] h-[100%]">
      <AppRoutes />
    </div>
  );
}

export default App;
