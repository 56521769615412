import React from 'react'

export default function IsDelete({id, visibles, setVisible, delfunc}) {
   
    let on = visibles


    const deletedYes = (id) => {
        delfunc(id)
    }

    const deletedNo = (e, id) => {
      setVisible(e, id)
    }

  return (
  on ? ( <div onClick={(e) => e.stopPropagation()}  className='fixed flex top-0 bottom-0 right-0 left-0 justify-center items-center w-screen h-screen  bg-white/20'>
    <div className='flex flex-col justify-center rounded-sm items-center lg:w-[20%] lg:h-[17%] sm:w-[70%] h-[20%] z-10 bg-white/90'>
    <h2 className='text-black font-bold mb-8'> Вы серьезно удалить ? </h2>
    <div className='w-[100%] flex items-center justify-center'>
       <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] mr-8 bg-primary w-[25%]' onClick={() => deletedYes(id)} >Да</button>
       <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] bg-rose-700 w-[25%]' onClick={(e) => deletedNo(e, id)}>Нет</button>
    </div>
</div>
  </div>):("")
  )
}
