import React from 'react'
import Alert from '@mui/material/Alert';
import { useRecoilValue } from 'recoil';
import { userInfo, isAuthAlert, isDelAlert, isLogOutAlert, isUpdateUserAlert, isSectorAddAlert, isSectorPutAlert, isSalaryPostAlert, isSalaryPutAlert, isSalaryDeleteAlert} from '../../../atom'
export default function AlertModal() {

    
    const user = useRecoilValue(userInfo)
    const authAlert = useRecoilValue(isAuthAlert)
    const delAlert = useRecoilValue(isDelAlert)
    const logoutAlert = useRecoilValue(isLogOutAlert)
    const upDateAlert = useRecoilValue(isUpdateUserAlert)

    const sectorAddAlert = useRecoilValue(isSectorAddAlert)
    const sectorPutAlert = useRecoilValue(isSectorPutAlert)
    
    const postSalary = useRecoilValue(isSalaryPostAlert)
    const putSalary = useRecoilValue(isSalaryPutAlert)
    const removeSalary = useRecoilValue(isSalaryDeleteAlert)

  return (
    <div className='absolute w-[100%]'>

         {authAlert &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Добро пожаловать {user.name} </Alert>}

    {delAlert &&  <Alert
    sx={{
      backgroundColor: '#dc2a2a7e',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Ваш аккаунт удален {user.name} </Alert>}

    {logoutAlert &&  <Alert
    sx={{
      backgroundColor: '#dca32a7e',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"85%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Вы вышли из аккаунта {user.name} </Alert>}

    {upDateAlert &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Ваш аккаунт был обновлен {user.name} </Alert>}

    {sectorAddAlert &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Добавлены цены секторов </Alert>}

    {sectorPutAlert &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Цены в секторах изменились </Alert>}

    {postSalary &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"100%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Зарплата записана </Alert>}

    {putSalary &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success"> Зарплата обновлена </Alert>}

    {removeSalary &&  <Alert
    sx={{
      backgroundColor: '#76e28c46',
      color:'white',
      textAlign:'center',
      transition:'ease-in-out',
      maxWidth: { lg:"60%", xs:"80%"},
      fontSize:{lg:'120%', xs:'90%'},
      position:'absolute',
      top:'12%',
      left:{lg:'12%', xs:'0%'}
    }}
     severity="success">Заработная плата удалена </Alert>}
     
    </div>
  )
}
