import React, {useState} from 'react'
import {registerMessage, isAuth, userInfo} from '../atom/index'
import '../styles/register.css'
import { Redirect } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { register } from '../api/services/AuthService'


export default function Register() {
    const [message, setMessage] = useRecoilState(registerMessage)
    const [auth, setAuth] = useRecoilState(isAuth)
    const [user, setUser] = useRecoilState(userInfo)
    const [redirect, setRedirect] = useState(false);
    

    const [name, setName] = useState('')
    const[surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirm, setPassword_confirm] = useState('')
    

    
    const  registeration = async() => {
        try {
            const res = await register(name, surname, email, password, password_confirm)
            if(res.status === 400){
               setMessage(res.data.message) 
               setRedirect(false)
            }
            else{
                localStorage.setItem('token', res.data.accessToken)
                 setAuth(true)
                 setUser(res.data.info)
                 setRedirect(true);
                 setMessage('') 
            }
        } catch (error) {
           console.error("Произошла ошибка:", error);
          setMessage('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
    }

  return (
    <div className='w-screen h-screen bg-bg flex lg:justify-start items-center lg:px-[2%] lg:flex-row sm:px-[5%] sm:justify-start sm:flex-col'>
         <div className=" flex flex-col items-center lg:mr-[4%] sm:mr-0 lg:mt-19 lg:h-[60%] sm:items-center sm:h-[30%] sm:mt-[20%] sm:mb-[20%]">
                    <h1 className="sm:text-6xl lg:text-8xl lg:px-2 text-white">    
                        Salary <span className="font-bold text-primary">Calc</span>
                    </h1>
                    <p className='text-white flex mt-[9%] lg:text-[100%] sm:text-[80%]'>Возможность рассчитывать и сохранять свою зарплату</p>
                    <p className='text-secondary lg:mt-[17%] sm:mt-[15%] sm:text-xxl'>Внимание!</p>
                    <p className='text-white lg:mt-[3%] sm:text-center sm:mt-[2%] sm:text-xxl'>Для завершения регистрации необходимо подтвердить ваш адрес электронной почты в течение двух минут.</p>
                </div>
        <div className="register">
             {message && <p className='relative bottom-[5%] text-red-700 text-sm'>{message}</p>}
                    <div className="registerBox">
                        <input className='input1' type="text" onChange={e => setName(e.target.value)} required='required' />
                        <span className='span1'>Имя</span>
                        <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[60%] lg:text-[70%] sm:w-[70%] sm:text-[55%] border bg-white text-black '>Имя должно быть длиннее 2 букв</p>
                    </div>
                    <div className="registerBox">
                        <input className='input2' type="text" required='required' onChange={e => setSurname(e.target.value)} />
                        <span className='span2'>Фамилия</span>
                        <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[60%] lg:text-[70%] border bg-white text-black sm:w-[85%] sm:text-[55%] '>Фамилия должна состоять более чем из 2 букв</p>
                    </div>
                    <div className="registerBox">
                        <input className='input3' type="text" required='required'onChange={e => setEmail(e.target.value)} />
                        <span className='span3'>Email</span>
                        <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[70%] lg:text-[70%] border sm:w-[100%] sm:text-[55%] bg-white text-black '>Необходимо иметь действующую электронную почту</p>
                    </div>
                    <div className="registerBox">
                        <input className='input4' type="password" required='required' onChange={e => setPassword(e.target.value)}/>
                        <span className='span4'>Пароль</span>
                        <p className=' hidden absalute transition-[0.3s]  left-[30%] bottom-[20%] rounded-sm lg:w-[95%] lg:text-[70%] border bg-white text-black sm:w-[80%]  sm:text-[55%]'>Пароль должен быть длиннее 8 символов и не может содержать пробелов</p>
                    </div>
                    <div className="registerBox">
                        <input className='input5' type="password" required='required' onChange={e => setPassword_confirm(e.target.value)} />

                        <span className='span5'>Повторения</span>
                        <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[80%] lg:text-[70%] border bg-white text-black sm:w-[95%] sm:text-[55%]'>Дубликат пароля должен совпадать с основным паролем</p>
                    </div>

                    <button onClick={() => registeration()} className='transform flex justify-center items-center bg-primary sm:text-center sm:w-[45%] sm:h-[13%] lg:w-[29%] lg:h-[13%] relative lg:right-9 sm:right-5 rounded-sm text-white font-medium hover:scale-110 active:scale-125'>Регистрация</button>
                    {redirect && <Redirect to="/wait" />}
                </div>
    </div>
  )
}
