import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import Table from '../components/table/Table'
import ShowDetails from '../components/UI/Details/ShowDetails'
import del from '../components/UI/icons/delete.png'
import IsDelete from '../components/UI/modal/IsDelete'
import Footer from '../components/footer/Footer'
import { useRecoilState } from 'recoil'
import { getForMonth, getForMonthCalc } from '../atom/salary'
import { isAlert, isSalaryDeleteAlert, salaryGetMonthMessage } from '../atom'
import {  getForMonthSalary, removeSalary } from '../api/services/SalaryService'



export default function Foramonth() {

const [sal, setSal] = useRecoilState(getForMonth)
const [calc, setCalc] = useRecoilState(getForMonthCalc)
const [messageGet, setMessageGet] = useRecoilState(salaryGetMonthMessage)

const [content, setContent] = useState(false)


const [modalVisibility, setModalVisibility] = useState({})

const [delVisibility, setDelVisibility] = useState({})

const [alert, setAlert] = useRecoilState(isAlert)
const [removeAlert, setRemoveAlert] = useRecoilState(isSalaryDeleteAlert)

const delfunc = async(id) => {
  try{
      const res = await removeSalary(id)
   if(res.status === 400){
      return null
  }
  const newSals = sal.filter(sal => sal.id !== id)
  setSal(newSals)
  setAlert(true)
  setRemoveAlert(false)

  setTimeout(() => {
    setRemoveAlert(false) 
    setAlert(false)
  }, 3000);

  }
  catch(error){
    console.error("Произошла ошибка:", error);
  }

}

const getSalaryMonth = async(month, year) =>{
  try {
    const res = await getForMonthSalary(month, year)
    if(res.status === 400){
      setSal([])
      setCalc(0)
      return setMessageGet(res.data.message)
    }
    setSal(res.data.salary.salary)
    setCalc(res.data.salary.calc)
    setMessageGet('')
  } catch (error) {
    console.error("Произошла ошибка:", error);
    setMessageGet('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
  }
}

const toggleModalVisibility = (id) => {
  setModalVisibility(prev => ({
    ...prev,
    [id]: !prev[id]
  }))
}

const toggleDelvisibility = (e, id) => {
  e.stopPropagation()
  setDelVisibility(prev =>({
    ...prev,
    [id]: !prev[id]
  }))
}

  useEffect(() => {
  if(sal.length === 0){
    setContent(true)
  }
}, [])

  return (
    <div className='h-[100vh] w-[100vw] bg-bg '>
      <Navbar/>
      <div className='xl:h-[6%] lg:h-[9%] xl:mt-[1%] lg:mt-[1.5%] sm:h-[6%] sm:mt-[4%] flex justify-center items-center'>
       <Table salary={getSalaryMonth} values={sal} setValues={setSal}/> 
       <div className='text-white border w-[auto] h-[100%] flex justify-center items-center font-bold border-secondary rounded-r-[5px] lg:px-[1%] sm:px-[2%]'>{calc} &#8381;</div>
      </div>
      
      <div className='w-[auto] h-[auto] mt-12 px-[7%] bg-bg pb-10'>
        {sal.map((sal, index) => 
          <div key={sal.id} onClick={() => toggleModalVisibility(sal.id)} className='text-white lg:text-xl sm:text-[100%] flex items-center justify-between border border-primary rounded-sm p-2  hover:cursor-pointer lg:mb-[1.5%] sm:mb-[3%]'>

          {modalVisibility[sal.id]   && <ShowDetails key={sal.id} id={sal.id} values={sal} visible={modalVisibility[sal.id]} setVisible={() => toggleModalVisibility(sal.id)}  />}

         { delVisibility[sal.id] && <IsDelete key={index} id={sal.id} visibles={delVisibility[sal.id]}  setVisible={toggleDelvisibility}  delfunc={delfunc}/>}
            <h2>{sal.date}</h2>
            <h2>Пик: {sal.summPics}</h2>
            <h2>Сумма: {sal.salary} &#8381;</h2>
            <img className='lg:w-[3%] sm:w-[9%] hover:scale-110 active:scale-125' onClick={(e) => toggleDelvisibility(e, sal.id)} src={del}/>
          </div>
        )}
        {content ? (<h1 className='text-red-700 lg:text-[300%] lg:ml-[10%] lg:mb-[15%] lg:mt-[5%] sm:text-[200%] sm:ml-[3%] sm:mt-[0%]' >{messageGet}</h1>
        ):('')}
      
      </div>
      <Footer/>
    </div>
  )
}
