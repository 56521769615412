import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import '../styles/register.css'
import { useRecoilState } from 'recoil'
import { isAlert, isAuth, isDelAlert, isLogOutAlert, isUpdateUserAlert, userInfo, utilMessage } from '../atom'
import { logout, putUser, removeUser } from '../api/services/AuthService'
import { Redirect } from 'react-router-dom'
import SecPayModal from '../components/UI/modal/SecPayModal'
import { getSector } from '../api/services/SectorService'
import { getSectordata } from '../atom/salary'

export default function Account() {

    const [message, setMessage] = useRecoilState(utilMessage)
    const [user, setUser] = useRecoilState(userInfo)
    const [auth, setAuth] = useRecoilState(isAuth)

    const [alert, setAlert] = useRecoilState(isAlert)
    const [logOuthAlert, setLogOuthAlert] = useRecoilState(isLogOutAlert)
    const [deleteAlert, setDeleteAlert] = useRecoilState(isDelAlert)
    const [updateAlert, setUpdateAlert] = useRecoilState(isUpdateUserAlert)

    const [modal, setModal] = useState(false)

    const [home, setHome] = useState(false)

    const [name, setName] = useState(user.name)
    const [surname, setSurname] = useState(user.surname)
    const [password, setPassword] = useState('')
    const [new_password, setNew_password] = useState('')
    const [new_password_confirm, setNew_password_confirm] = useState('')

    const [nameChange, setNamechange] = useState(false)
    const [passwordChange, setPasswordChange] = useState(false)
    const [deleteVis, setDeleteVis] = useState(false)
    const [logoutVis, setlogoutVis] = useState(false)
    const [secPay, setSecpay] = useState(false)


    const updateUser = async () =>{
        try {
            const res = await putUser(name, surname, password, new_password, new_password_confirm)
            if(res.status === 400){
                return setMessage(res.data.message)
            }
            else{
                setUser(res.data.info)
                setMessage('')

                setHome(true)
                setAlert(true)
                setUpdateAlert(true)

                setTimeout(() => {
                setUpdateAlert(false)
                setAlert(false)
            }, 3000);

            }
        } catch (error) {
            console.error("Произошла ошибка:", error);
            setMessage('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
    }

    const deleteUser = async () =>{
        try {
            const res = await removeUser()
            if(res.status === 400){
                setMessage(res.data.message)
                setDeleteVis(false)
            }
            setMessage('')
            setAuth(false)
            localStorage.removeItem('token')
            setHome(true)
            setDeleteVis(false)
            setAlert(true)
            setDeleteAlert(true)
            setTimeout(() => {
                setDeleteAlert(false)
                setAlert(false)
                window.location.reload()
            }, 3000);
           
        } catch (error) {
            console.error("Произошла ошибка:", error);
            setMessage('Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
    }

    const logoutUser = async () =>{
        try {
            const res = await logout()

            if(res.status === 400){
                setMessage(res.data.message)
            }
            setMessage('')
            localStorage.removeItem('token')
            setAuth(false)
            setHome(true)
            setlogoutVis(false)
            setAlert(true)
            setLogOuthAlert(true)
            setTimeout(() => {
                setLogOuthAlert(false)
                setAlert(false)
                window.location.reload()
            }, 3000);
            
        } catch (error) {
            console.error("Произошла ошибка:", error);
            setMessage('Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
    }


    return (
        <div className=' h-screen w-screen bg-bg'>
            <Navbar />
            <SecPayModal visible={modal} setVisible={setModal}/>
            <div className='flex lg:justify-between items-center w-[100%] lg:h-[82%]  lg:px-[5%] lg:flex-row sm:px-[5%] sm:h-[90%] sm:justify-start sm:flex-col'>
                <div className=" flex flex-col justify-center items-center lg:mb-[18%] lg:mt-[15%] lg:pl-[5%] lg:h-[60%] sm:items-center sm:h-[30%] sm:mt-[10%] sm:mb-[10%]">
                    <h1 className="sm:text-6xl lg:text-8xl lg:px-2 text-white">
                        Salary <span className="font-bold text-primary">Calc</span>
                    </h1>
                    <p className='text-white flex mt-[9%] lg:text-[100%] sm:text-[80%]'>Возможность рассчитывать и сохранять свою зарплату</p>
                </div>
               {auth ? ( <div className=" flex lg:itmes-center lg:flex-row sm:flex-col sm:items-center lg:w-[50%] lg:h-[90%] sm:w-[100%] sm:h-[80%]">
                    
                    <div className='flex flex-col lg:w-[100%] lg:mb-0 lg:mt-[0%] sm:mb-[7%] sm:w-[65%]'>
                    {message && <p className='relative bottom-[15%] mb-2 text-red-700 text-sm'>{message}</p>}
                        <button onClick={() => setNamechange(!nameChange)} className='text-white border  rounded-sm active:scale-110 font-medium lg:text-[120%] lg:w-[97%] mb-[10%] '>Изменение имени и фамилии</button>
                        <button onClick={() => setPasswordChange(!passwordChange)} className='text-white border  rounded-sm active:scale-110 font-medium lg:text-[120%] lg:w-[97%] lg:mb-[10%] sm:mb-[10%] '>Изменить пароль</button>
                        
                       { deleteVis ? ( <div onClick={(e) => e.stopPropagation()}  className='fixed flex top-0 bottom-0 right-0 left-0 justify-center items-center w-screen h-screen  bg-white/20'>
                        <div className='flex flex-col justify-center rounded-sm items-center lg:w-[20%] lg:h-[17%] sm:w-[70%] h-[20%] z-10 bg-white/90'>
                       <h2 className='text-black font-bold mb-8'> Вы серьезно удалите пользователя? </h2>
                       <div className='w-[100%] flex items-center justify-center'>
                        <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] mr-8 bg-primary w-[25%]' onClick={() => deleteUser()} >Да</button>
                        <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] bg-rose-700 w-[25%]' onClick={() => setDeleteVis(false)}>Нет</button>
                         </div>
                        </div>
                         </div>):("")}

                       { logoutVis ? ( <div onClick={(e) => e.stopPropagation()}  className='fixed flex top-0 bottom-0 right-0 left-0 justify-center items-center w-screen h-screen  bg-white/20'>
                        <div className='flex flex-col justify-center rounded-sm items-center lg:w-[20%] lg:h-[17%] sm:w-[70%] h-[20%] z-10 bg-white/90'>
                       <h2 className='text-black font-bold mb-8'> Вы действительно хотите выйти? </h2>
                       <div className='w-[100%] flex items-center justify-center'>
                        <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] mr-8 bg-primary w-[25%]' onClick={() => logoutUser()} >Да</button>
                        <button className='transform hover:scale-110 active:scale-125 text-black border rounded-[4px] bg-rose-700 w-[25%]' onClick={() => setlogoutVis(false)}>Нет</button>
                         </div>
                        </div>
                         </div>):("")}

                        <button onClick={() => {setModal(true)}} className='text-white border  rounded-sm active:scale-110 font-medium lg:text-[120%] lg:w-[97%] lg:mb-[10%] sm:mb-[10%]'>Изменение Цен сектора</button>

                        <button onClick={() => {setDeleteVis(true)}} className='text-white border  rounded-sm active:scale-110 font-medium lg:text-[120%] lg:w-[97%] lg:mb-[10%] sm:mb-[10%]'>Удалить аккаунт</button>

                        <button onClick={() => {setlogoutVis(true)}} className='text-white border  rounded-sm active:scale-110 font-medium lg:text-[120%] lg:w-[97%] lg:mb-[10%] sm:mb-[10%]'>Выйти из аккаунта</button>

                        <button className={` ${ nameChange | passwordChange ? ('flex') : ('hidden text-bg')}transform justify-center items-center bg-primary lg:text-center sm:text-center lg:w-[30%] lg:h-[7%] ml-[28%] sm:w-[45%] sm:h-[30%] rounded-sm text-white font-medium hover:scale-110 active:scale-125`} onClick={ () => updateUser()}>Сохранять</button>
                    </div>
                        <div className="w-[100%] h-[100%] lg:mt-[20%] ">
                            {nameChange ? (
                        <div className='lg:h-[25%]  flex flex-col lg:items-end sm:items-center sm:h-[33%]'>
                            <div className="lg:h-[40%] lg:w-[90%] mb-[5%] sm:w-[60%] sm:h-[40%]">
                                <input className='input1' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[80%] lg:text-[70%] sm:w-[65%] sm:text-[60%] border bg-white text-black '>Имя должно быть длиннее 2 букв</p>
                            </div>
                            <div className="lg:h-[40%] lg:w-[90%] sm:w-[60%] sm:h-[40%]">
                                <input className='input1' type="text" required='required' value={surname} onChange={(e) => setSurname(e.target.value)} />
                                <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[85%] lg:text-[70%] border bg-white text-black sm:w-[85%] sm:text-[55%] '>Фамилия должна состоять более чем из 2 букв</p>
                            </div>
                        </div>
                    ) : ('')}                    
                    {passwordChange ? (
                        <div className='flex flex-col lg:items-end lg:h-[60%] lg:mb-[5%] sm:items-center sm:h-[auto]'>
                            <h1 className='text-white text-center lg:mr-[40%] sm:mr-0'>Пароль</h1>
                            <div className="lg:h-[17%] lg:w-[90%] lg:mb-[3%] sm:w-[60%] sm:h-[20%] ">                            
                                <input className='input1' type="password" required='required' onChange={e => setPassword(e.target.value) } />
                                <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[60%] lg:text-[70%] border sm:w-[75%] sm:text-[65%] bg-white text-black '>Повторите предыдущий пароль</p>
                            </div>
                            <h1 className='text-white text-center lg:mr-[29%] sm:mr-0'>Новый пароль</h1>
                            <div className="lg:h-[17%] lg:w-[90%] lg:mb-[3%] sm:w-[60%] sm:h-[20%]">
                                <input className='input1' type="password" required='required' onChange={e => setNew_password(e.target.value)} />
                                <p className=' hidden absalute transition-[0.3s]  left-[60%] bottom-[20%] rounded-sm lg:w-[101%] lg:text-[70%] border bg-white text-black  sm:text-[65%]'>Пароль должен быть длиннее 8 символов и не может содержать пробелов</p>
                            </div>
                            <h1 className='text-white text-center lg:mr-[25%] sm:mr-0'>Повторите пароль</h1>
                            <div className="lg:h-[17%] lg:w-[90%] sm:w-[60%] sm:h-[20%]">
                                <input className='input1' type="password" required='required' onChange={e => setNew_password_confirm(e.target.value)} />
                                <p className=' hidden absalute transition-[0.3s] text-center left-[60%] bottom-[20%] rounded-sm lg:w-[95%] lg:text-[70%] border bg-white text-black sm:w-[100%] sm:text-[65%]'>Дубликат пароля должен совпадать с основным паролем</p>
                            </div>
                        </div>
                    ) : ('')}
                    
                        </div>
                
                </div>) : (<h1 className='text-white lg:text-[400%] sm:text-[200%]'>Пройдите авторизацию</h1>)}
            </div>
            {home && <Redirect to='/home' />}
        </div>
    )
}
