import React, { useState } from "react";
import "../../../styles/showmodal.css";
import '../../../styles/modal.css'
import '../../../styles/updateModal.css'
import add2 from "../icons/add2.png";
import { useRecoilState } from "recoil";
import { isAlert, isSalaryPutAlert, salaryPutMessage } from "../../../atom";
import { pustSalary } from "../../../api/services/SalaryService";

function ShowDetails({id, visible, setVisible, values}) {
  let on = visible;

  const [updateVis, setUpdateVis] = useState(false);
  const [salary, setSalary] = useState(values);
  const [comment, setComment] = useState("")
  
  const [update, setUpdate] = useState({
    os: salary.pics.os,
    osmulti: salary.pics.osmulti,
    oxl: salary.pics.oxl,
    oxlmulti: salary.pics.oxlmulti,
    zam: salary.pics.zam,
    zammulti: salary.pics.zammulti,
    fresh: salary.pics.fresh,
    freshmulti: salary.pics.freshmulti,
    oskam: salary.pics.oskam,
    oskammulti:salary.pics.oskammulti
  })


  const [message, setMessage ]= useRecoilState(salaryPutMessage)
  const [alert, setAlert] = useRecoilState(isAlert)
  const [alertPut, setAlertPut] = useRecoilState(isSalaryPutAlert)
 
  const updateChange = (e) =>{
    setUpdate((prev) => ({
      ...prev,
      [e.target.name]: Number(e.target.value)
    }))
  }

  const endVisibleUpd = () =>{
    setVisible(id)
    setUpdateVis(!updateVis)
  }
 
  const toUpdate  = async() => {
    try {
      const res = await pustSalary(id,{pics:update, comment:comment})
      if(res.status === 400){
       return setMessage(res.data.message)
      }
      setMessage('')
      setAlert(true)
      setAlertPut(true)
      setVisible(id)
      setUpdateVis(!updateVis)
      

      setTimeout(() => {
        setAlertPut(false) 
        setAlert(false)
        window.location.reload()
      }, 3000);
  
    } catch (error) {
       console.error("Произошла ошибка:", error);
       setMessage(' Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
    }
  }


  return( on ? ( updateVis ? (<div onClick={() => endVisibleUpd()} className='fixed flex justify-center items-center top-0 bottom-0 right-0 left-0 w-screen h-screen z-10 bg-black/70'>

    <div className=' flex justify-center sm:relative sm:top-[2.5%] sm:pt-3 sm:w-[80%] sm:pb-4 sm:h-[90%] sm:items-center sm:pl-[17%] lg:flex-wrap lg:pl-[3%] lg:pr-[3%] flex-col lg:gap-y-4 lg:gap-x-4 sm:gap-x-0 sm:gap-[0.5%] lg:relative  rounded-lg lg:w-[50%] lg:h-[60%] bg-bg lg:pt-[1%]' onClick={(e) => e.stopPropagation()}>

    <div className="inputBox3">
            <input type="number" name="os"  onChange={e => updateChange(e)} />
            <span>Основной</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="osmulti"  onChange={e => updateChange(e)} />
            <span>ОСМульти</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="oxl"  onChange={e => updateChange(e)} />
            <span>Охлождёнка</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="oxlmulti"  onChange={e => updateChange(e)} />
            <span>ОхлМульти</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="zam" onChange={e => updateChange(e)} />
            <span>Заморозка</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="zammulti"  onChange={e => updateChange(e)} />
            <span>Заммульти</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="fresh"  onChange={e => updateChange(e)} />
            <span>Фреш</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="freshmulti" onChange={e => updateChange(e)} />
            <span>ФрешМульти</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="oskam" onChange={e => updateChange(e)} />
            <span>Оскамера</span>
          </div>
          <div className="inputBox3">
            <input type="number" name="oskammulti" onChange={e => updateChange(e)} />
            <span>КамМульти</span>
          </div>
    {message && <p className='relative  text-red-700 text-sm'>{message}</p>}
    <div className="textBox3">
        <textarea  onChange={e => setComment(e.target.value)}/>
        <span >Коммент</span>
    </div>

  <div className="flex lg:w-[40%] sm:w-[100%] lg:ml-[-35%] sm:ml-0 ">
  <div onClick={() => toUpdate()} className=" flex justify-center items-center transition ease-out border-none rounded-full border font-medium text-white bg-primary cursor-pointer mr-2 lg:hover:scale-110  lg:active:scale-125  lg:w-[130px] lg:h-[46px] lg:mr-[5%] lg:text-base  lg:py-2 sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[100%]  sm:text-[100%] sm:hover:scale-110  sm:active:scale-125"><img className='lg:w-[30px] mr-2 lg:h-[28px] sm:w-[30%] sm:h-[75%]' src={add2}/>Salary</div>

<div
      onClick={() => {
        endVisibleUpd(id)
      }}
      className=" flex justify-center items-center transition ease-out border-none rounded-full border font-medium text-white bg-rose-700 cursor-pointer lg:hover:scale-110 lg:active:scale-125 lg:w-[50%] lg:h-[80%] lg:text-base sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[100%] sm:text-[100%] sm:hover:scale-110 sm:active:scale-125">
      Exit
    </div>

  </div>
    </div>
</div>) : (<div
      onClick={() => {
        setVisible(id)
      }}
      className="fixed flex justify-center items-center top-0 bottom-0 right-0 left-0 w-screen h-screen z-10 bg-black/70"
    >
      <div
        className=" flex lg:flex-row lg:gap-y-1 lg:w-[80%] lg:h-[65%] bg-bg sm:w-[100%] sm:h-[auto] sm:gap-[0.5%] sm:flex-col sm:justify-center sm:items-center"
        onClick={(e) => e.stopPropagation()}>

        <div className="flex justify-center flex-wrap lg:ml-0 lg:flex-col lg:mt-6 lg:gap-y-6 rounded-lg lg:w-[100%] lg:h-[95%] bg-bg sm:h-[100%] sm:w-[100%] sm:items-center sm:mt-[15%] sm:ml-[18%] sm:gap-y-5 ">

        <h2 className=" lg:hidden sm:block w-[100%] ml-[34%]">Сумма</h2>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.os} readOnly />
            <span>Основной</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.osmulti} readOnly />
            <span>ОСМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.oxl} readOnly />
            <span>Охлождёнка</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.oxlmulti} readOnly />
            <span>ОхлМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.zam} readOnly />
            <span>Заморозка</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.zammulti} readOnly />
            <span>Заммульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.fresh} readOnly />
            <span>Фреш</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.freshmulti} readOnly />
            <span>ФрешМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.oskam} readOnly />
            <span>Оскамера</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.sectors.oskammulti} readOnly />
            <span>КамМульти</span>
          </div>

          <h2 className=" lg:hidden sm:block w-[100%] ml-[37%]">Пик</h2>

          <div className="inputBox1">
            <input type="number" value={salary.pics.os} readOnly />
            <span>Основной</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.osmulti} readOnly />
            <span>ОСМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.oxl} readOnly />
            <span>Охлождёнка</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.oxlmulti} readOnly />
            <span>ОхлМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.zam} readOnly />
            <span>Заморозка</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.zammulti} readOnly />
            <span>Заммульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.fresh} readOnly />
            <span>Фреш</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.freshmulti} readOnly />
            <span>ФрешМульти</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.oskam} readOnly />
            <span>Оскамера</span>
          </div>
          <div className="inputBox1">
            <input type="number" value={salary.pics.oskammulti} readOnly />
            <span>КамМульти</span>
          </div>

        </div>

        <div className="flex flex-col lg:w-[40%] lg:h-[60%] lg:mt-[20%] sm:mt-0 sm:h-[180px] sm:w-[70%] sm:mb-[16%]">
          <div className="textBox1">
          <textarea value={salary.comment} readOnly />
          <span>Коммент</span>
        </div>
            <div className="flex">
             <button 
              className="justify-center items-center transition ease-out hover:scale-110  active:scale-125  lg:w-[30%] lg:h-[100%] mr-[16%] border-none bg-indigo-500 lg:text-base font-medium text-white lg:flex rounded-[3px] sm:h-[95%] sm:w-[40%] sm:text-sm"
              onClick={() => setUpdateVis(!updateVis)}>
              Изменить
            </button>
        <div
          onClick={() => {
            setVisible(id)
          }}
          className=" flex justify-center items-center transition ease-out border-none rounded-full border font-medium text-white bg-rose-700 cursor-pointer lg:hover:scale-110 lg:active:scale-125 lg:w-[30%] lg:h-[110%] lg:text-base sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[95%] sm:text-[100%] sm:hover:scale-110 sm:active:scale-125">
          Выход
        </div>
            </div>
          
        </div>
      </div>
    </div>)
    
  ) : ("") )
}

export default ShowDetails