import { atom } from "recoil";

export const isAuth = atom({
    key:"auth",
    default: false
})
export const isSecpay = atom({
    key:"iSecpay",
    default: false
})

export const isAlert = atom({
    key:"isAlert",
    default: false
})

export const isAuthAlert = atom({
    key:"isAuthAlert",
    default: false
})

export const isDelAlert = atom({
    key:"isDelAlert",
    default: false
})

export const isLogOutAlert = atom({
    key:"isLogOutAlert",
    default: false
})

export const isUpdateUserAlert = atom({
    key:"isUpdateUserAlert",
    default: false
})

export const isSectorAddAlert = atom({
    key:"isSectorAddAlert",
    default: false
})

export const isSectorPutAlert = atom({
    key:"isSectorPutAlert",
    default: false
})

export const isSalaryPostAlert = atom({
    key:"isSalaryPostAlert",
    default: false
})

export const isSalaryPutAlert = atom({
    key:"isSalaryPutAlert",
    default: false
})

export const isSalaryDeleteAlert = atom({
    key:"isSalaryDeleteAlert",
    default: false
})

export const userInfo = atom({
    key:'user',
    default:{
        name:'',
        surname:''
    }
})

export const registerMessage = atom({
    key:"registerMessage",
    default: ''
})
export const utilMessage = atom({
    key:"utilMessage",
    default: ''
})
export const loginMassage = atom({
    key:"loginMassage",
    default: ''
})

export const waitMessage = atom({
    key:"waitMessage",
    default: ''
})

export const salarMessage = atom({
    key:"salaryMessage",
    default: ''
})

export const salaryGetMessage = atom({
    key:"salarGetMessage",
    default: ''
})

export const sectorMessage = atom({
    key:"sectorMessage",
    default: ''
})

export const salaryPutMessage = atom({
    key:"salaryPutMessage",
    default: ''
})

export const salaryDeleteMessage = atom({
    key:"salaryDeleteMessage",
    default: ''
})

export const salaryGetMonthMessage = atom({
    key:"salaryGetMonthMessage",
    default: ''
})

