import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import ShowDetails from '../components/UI/Details/ShowDetails'
import del from '../components/UI/icons/delete.png'
import IsDelete from '../components/UI/modal/IsDelete'
import Footer from '../components/footer/Footer'
import icons from '../components/UI/button/icons'
import { isAlert, isSalaryDeleteAlert, salaryGetMonthMessage } from '../atom'
import { useRecoilState } from 'recoil'
import { getForLifetime, getForLifetimeCalc } from '../atom/salary'
import { getByLifTimeSalary, removeSalary } from '../api/services/SalaryService'
import { format } from 'date-fns'


export default function Foralifetime() {

  const [sal, setSal] = useRecoilState(getForLifetime)
  const [calc, setCalc] = useRecoilState(getForLifetimeCalc)
  const [messageGet, setMessageGet] = useRecoilState(salaryGetMonthMessage)
  
  const [modalVisibility, setModalVisibility] = useState({})
  const [delVisibility, setDelVisibility] = useState({})
  
  const [content, setContent] = useState(false)

  const [gte, setGte] = useState('')
  const [lte, setLte] = useState('')
  
  const [alert, setAlert] = useRecoilState(isAlert)
  const [removeAlert, setRemoveAlert] = useRecoilState(isSalaryDeleteAlert)
  
  const getSalaryLifetime = async() =>{
    try {
      const res = await getByLifTimeSalary(gte, lte)
      if(res.status === 400){
        setSal([])
        setCalc(0)
        return setMessageGet(res.data.message)
      }
      setSal(res.data.salary.salary)
      setCalc(res.data.salary.calc)
      setMessageGet('')
    } catch (error) {
      console.error("Произошла ошибка:", error);
      setMessageGet('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
    }
  }
  
  const delfunc = async(id) => {
    try{
        const res = await removeSalary(id)
     if(res.status === 400){
        return null
    }
    const newSals = sal.filter(sal => sal.id !== id)
    setSal(newSals)
    setAlert(true)
    setRemoveAlert(false)
  
    setTimeout(() => {
      setRemoveAlert(false) 
      setAlert(false)
    }, 3000);
  
    }
    catch(error){
      console.error("Произошла ошибка:", error);
    }
  
  }
  
  const toggleModalVisibility = (id) => {
    setModalVisibility(prev => ({
      ...prev,
      [id]: !prev[id]
    }))
  }
  
  const toggleDelvisibility = (e, id) => {
    e.stopPropagation()
    setDelVisibility(prev =>({
      ...prev,
      [id]: !prev[id]
    }))
  }
  
  useEffect(() => {
    if(sal.length === 0){
      setContent(true)
    }
  }, [])
  return (
    <div className='h-[100vh] w-[100vw] bg-bg '>
      <Navbar/>
      <div className='xl:h-[6%] lg:h-[9%] xl:mt-[1%] lg:mt-[1.5%] sm:h-[10%] sm:mt-[4%] flex lg:flex-row sm:flex-wrap justify-center items-center '>

      <input type="date" className='outline-none lg:ml-0 lg:w-[15%] lg:h-[50%] lg:mx-[4%] rounded-sm bg-gray-200 flex justify-center sm:w-[33%] sm:h-[40%] sm:ml-7' onChange={e => setGte(format(new Date(e.target.value), 'dd.MM.yyyy'))}/> 
      <hr className="border-white w-[4%] lg:mx-0 sm:mx-[3%]"/> 
      <input type="date" className='outline-none lg:w-[15%] lg:h-[50%] lg:ml-[4%] rounded-sm bg-gray-200 flex justify-center sm:w-[33%] sm:h-[40%]'  onChange={e => setLte(format(new Date(e.target.value), 'dd.MM.yyyy'))} /> <div onClick={() => getSalaryLifetime()} className='mx-[2%]'>{icons.search_btn}</div>
      
       <div className='text-white border w-[auto] lg:h-[60%] lg:mt-0 flex justify-center items-center border-secondary font-bold rounded-[5px] lg:px-[1%] sm:px-[2%] sm:h-[40%] sm:mt-3'>{calc} &#8381;</div>
      </div>
      
      <div className='w-[auto] h-[auto] mt-12 px-[7%] bg-bg pb-10'>
        {sal.map((sal, index) => 
          <div key={sal.id} onClick={() => toggleModalVisibility(sal.id)} className='text-white lg:text-xl sm:text-[100%] flex items-center justify-between border border-primary rounded-sm p-2  hover:cursor-pointer lg:mb-[1.5%] sm:mb-[3%]'>

          {modalVisibility[sal.id]   && <ShowDetails key={sal.id} id={sal.id} values={sal} visible={modalVisibility[sal.id]} setVisible={() => toggleModalVisibility(sal.id)}  />}

         { delVisibility[sal.id] && <IsDelete key={index} id={sal.id} visibles={delVisibility[sal.id]}  setVisible={toggleDelvisibility}  delfunc={delfunc}/>}
            <h2>{sal.date}</h2>
            <h2>Пик: {sal.summPics}</h2>
            <h2>Сумма: {sal.salary} &#8381;</h2>
            <img className='lg:w-[3%] sm:w-[9%] hover:scale-110 active:scale-125' onClick={(e) => toggleDelvisibility(e, sal.id)} src={del}/>
          </div>
        )}
        {content ? (<h1 className='text-red-700 lg:text-[300%] lg:ml-[10%] lg:mb-[15%] lg:mt-[5%] sm:text-[200%] sm:ml-[3%] sm:mt-[0%]' >{messageGet}</h1>
        ):('')}
      
      </div>
      <Footer/>
    </div>
  )
}
