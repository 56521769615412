import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { isactivated } from '../api/services/AuthService'
import { useRecoilState } from 'recoil'
import { isAlert, isAuthAlert, waitMessage } from '../atom'

export default function Wait() {

  const [message, setMessage] = useRecoilState(waitMessage)
  const [home, setHome] = useState(false)
  const [reg, setReg] = useState(false)
  const [title, setTitle] = useState("")

  const [alert, setAlert] = useRecoilState(isAlert)
  const [authAlert, setAuthAlert] = useRecoilState(isAuthAlert)

 const isAct = async () =>{
try {
  const res = await isactivated()
    setMessage(res.data.message)

  if(message === 'Пользователь не найден'){
    setTimeout(() => {
      setReg(true)
    }, 3000);
    return setTitle("Вы не активированы !")
  }
  if(message === 'Неактивен'){
    return setTitle("Вы можете активировать по электронной почте !")
  }
  if(message === 'Активен'){
    setTimeout(() => {
      setHome(true)
    }, 3000);
    setAlert(true)
    setAuthAlert(true)
    setTimeout(() => {
      setAuthAlert(false)
        setAlert(false)
    }, 5000);
    return setTitle("Вы были активированы !")
  }
} catch (error) {
  console.error("Произошла ошибка:", error);
  setTitle('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
}
    
    
 }

  return (
    <div className='h-screen w-screen bg-bg flex flex-col justify-around items-center lg:py-[10%] sm:py-[50%]'>
       <h1 className="sm:text-6xl lg:text-8xl lg:px-2  text-white"> Salary <span className="font-bold text-primary">Calc</span></h1>
                    <p className='text-secondary lg:text-[100%] '>{title}</p>
      {home && <Redirect to="/home" /> }
      {reg && <Redirect to="/registration" /> }
      <button onClick={() => isAct()} className='transform flex justify-center items-center bg-primary sm:text-center sm:w-[25%] sm:h-[8%] lg:w-[12%] lg:h-[9%] rounded-sm text-white font-medium hover:scale-110 active:scale-125'>Обновить</button>
    </div>
  )
}
