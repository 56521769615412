import sidebar from '../icons/sidebar.png'
import search from '../icons/search.png'
import add from '../icons/add.png'
import add2 from '../icons/add2.png'
import auth from '../icons/profile.png'
import register from '../icons/registered.png'
import info from '../icons/information.png'
import telegram from '../icons/telegram.png'
import instagram from '../icons/instagram.png'
import exit from '../icons/exit.png'
import month from '../icons/one.png'
import lifetime from '../icons/lifetime.png'
import all from '../icons/budget.png'
import util from '../icons/utilization.png'

const icons = {
  open_btn:  <div className="flex items-center justify-center lg:mt-2 lg:w-[50px]  lg:h-[45px] transition ease-out rounded-sm bg-primary hover:scale-110 hover:bg-indigo-500 duration-150 sm:w-[35px] sm:h-[30px]"><img className='lg:w-[30px] sm:w-[20px]' src={sidebar} alt='Sidebar'/></div>,

  search_btn: <div><img className=' transition ease-in-out active:lg:w-[35px] active:lg:h-[33px] hover:lg:w-[33px] hover:lg:h-[31px] lg:w-[30px] lg:h-[28px] sm:w-[20px] active:sm:w-[25px] active:sm:h-[23px] hover:sm:w-[23px] hover:sm:h-[21px] ' src={search}/></div>,

  add_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[140px] lg:h-[56px] border-none bg-primary text-base font-medium text-white sm:hidden md:flex justify-between items-center py-2 rounded-full border  px-5 "><img className='lg:w-[30px] lg:h-[28px] sm:w-[20px] sm:h-[18px]' src={add2}/>Salary</div>,

  auth_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[140px] lg:h-[56px] border-none bg-primary text-base font-medium text-white sm:hidden md:flex justify-between items-center py-2 rounded-full border  px-4 "><img className='lg:w-[40px] lg:h-[32px] sm:w-[20px] sm:h-[18px]' src={auth}/>Login</div>,

  add_icon: <img className="mr-4 w-9" src={add}/>,
  auth_icon: <img className="mr-4 w-9" src={auth}/>,
  month_icon: <img className="mr-4 w-8 " src={month}/>,
  lifetime_icon: <img className="mr-4 " src={lifetime}/>,
  allsalary_icon: <img className="mr-4 " src={all}/>,
  util: <img className="mr-4 w-9" src={util}/>, 
  information: <img className="mr-4 w-9" src={info}/>, 
  main_month_icon: <img className="lg:mr-4 lg:w-8 sm:mr-2 sm:w-5" src={month}/>,
  main_lifetime_icon: <img className="lg:mr-4 lg:w-8 sm:mr-2 sm:w-5" src={lifetime}/>,
  main_allsalary_icon: <img className="lg:mr-4 lg:w-8 sm:mr-2 sm:w-5" src={all}/>,

  telegram_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[140px] lg:h-[46px] border-none bg-primary lg:text-base font-medium text-white md:flex justify-between items-center lg:py-2 rounded-full border lg:px-4 sm:px-2 sm:w-[105%] sm:h-[160%] sm:flex sm:text-[65%]"><img className='lg:w-[35px] lg:h-[30px] sm:w-[17px] sm:h-[15px]' src={telegram}/>Telegram</div>,

  instagram_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[150px] lg:h-[46px] border-none bg-primary lg:text-base font-medium text-white md:flex justify-between items-center lg:py-2 rounded-full border lg:px-4 sm:px-2 sm:w-[105%] sm:h-[160%] sm:flex sm:text-[65%]"><img className='lg:w-[35px] lg:h-[30px] sm:w-[17px] sm:h-[15px]' src={instagram}/>Instagram</div>,

  info_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[150px] lg:h-[46px] border-none bg-primary lg:text-base font-medium text-white md:flex justify-between items-center lg:py-2 rounded-full border lg:px-2 sm:px-3 sm:w-[105%] sm:h-[160%] sm:flex sm:text-[70%]"><img className='lg:w-[35px] lg:h-[30px] sm:w-[17px] sm:h-[15px]' src={info}/>Инструкция</div>,

  register_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[150px] lg:h-[46px] border-none bg-primary lg:text-base font-medium text-white md:flex justify-between items-center lg:py-2 rounded-full border lg:px-2 sm:px-2 sm:w-[105%] sm:h-[140%] sm:flex sm:text-[70%]"><img className='lg:w-[35px] lg:h-[30px] sm:w-[17px] sm:h-[15px]' src={register}/>Регистрация</div>,

  close_btn:<div className=" transition ease-out w-[35px] h-[35px] hover:scale-[1.1] active:scale-[1.2]"> <img src={exit} /></div>,

  head_auth_btn:<div className=" transition ease-out hover:scale-110  active:scale-125  lg:w-[110px] lg:h-[46px] border-none bg-primary lg:text-base font-medium text-white md:flex justify-between items-center lg:py-2 rounded-full border lg:px-4 sm:px-2 sm:w-[105%] sm:h-[140%] sm:flex sm:text-[70%]"><img className='lg:w-[35px] lg:h-[30px] sm:w-[17px] sm:h-[15px]' src={auth}/>Логин</div>
}


export default icons
