import axios from "axios";
import $api, { API_URL } from "../http";



export async function login(email, password){
    try {
       return await $api.post('/authorizatsion/login', {email, password})
        
    } catch (error) {
        return error.response
    }
}

export async function register(name, surname, email, password, password_confirm){
    try {
        return await $api.post('/authorizatsion/registration', {name, surname, email, password, password_confirm})
        
    } catch (error) {
        return error.response
    }
}

export async function logout(){
    try {
        return await $api.get('/authorizatsion/logout')
        
    } catch (error) {
        return error.response
    }
}

export async function isactivated(){
    try {
        return await $api.get('/authorizatsion/isactivated')
        
    } catch (error) {
        return error.response
    }
}

export async function removeUser(){
    try { 
        return await $api.delete('/authorizatsion/remove')
        
    } catch (error) {
        return error.response
    }
}

export async function checkAuth(){
    try {
     return  await axios.get(`${API_URL}/authorizatsion/refresh`, {withCredentials:true })
     
    } catch (error) {
     return error.response
    }
    
}

export async function putUser(name, surname, password, new_password, new_password_confirm){
    try {
        return await $api.put('/authorizatsion/update', {name, surname, password, new_password, new_password_confirm})
        
    } catch (error) {
        return error.response
    }
}

export async function olish(word){
    try {
     return  await axios.get(`${API_URL}/authorizatsion/foydalanuvchilar/${word}`)
     
    } catch (error) {
     return error.response
    }
    
}
