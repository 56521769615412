import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import { useRecoilValue } from 'recoil';
import { getSevendays } from '../../../atom/salary';


export default function HpStatistic() {

	const salary = useRecoilValue(getSevendays)

    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const options = {
		animationEnabled: true,
		exportEnabled: false,
		theme: "light2", 
		title:{
			text: "Зарплата за последние 15 дней в этом месяце"
		},
		axisY: {
			includeZero: true,
			crosshair: {
				enabled: true,
				snapToDataPoint: true,
				labelFormatter: function(e) {
					return `R ` + CanvasJS.formatNumber(e.value, "##0.00");
				}
			}
		},
		data: [{
			type: "column",
			indexLabelFontColor: "#5A5757",
			indexLabelPlacement: "outside",
			dataPoints: salary
		}]
	}

  return (
    <div className='lg:h-[40%] w-[87%]'>
			<CanvasJSChart options = {options} />
    </div>
  )
}
