import React, { useState } from 'react'
import {format} from 'date-fns'
import '../../../styles/modal.css'
import '../../../styles/updateModal.css'
import add2 from '../icons/add2.png'
import { useRecoilState } from 'recoil'
import { isAlert, isSalaryPostAlert, isSecpay, isSectorAddAlert, sectorMessage } from '../../../atom'
import { postSector } from '../../../api/services/SectorService'
import { postSalary } from '../../../api/services/SalaryService'
import { postSalaryData } from '../../../atom/salary'

export default function AddModal(props) {
    let on = props.values
   
    const [now, setNow] = useState(false)
    const [sector, setSector] = useRecoilState(isSecpay)
    const [alert, setAlert] = useRecoilState(isAlert)

    const [isResult, setIsResult] = useState(false)

    const [sectorAddAlert, setSectorAddAlert] = useRecoilState(isSectorAddAlert)
    const [salaryAlert, setSalaryAlert] = useRecoilState(isSalaryPostAlert)
    const[postData, setPostData] = useRecoilState(postSalaryData)

    const [secMessage, setSecMessage] = useRecoilState(sectorMessage)
    const [sectorData, setSectorData] = useState({
        os: 0,
        osmulti: 0, 
        zam: 0,
        zammulti: 0, 
        oxl: 0,
        oxlmulti: 0, 
        fresh: 0,
        freshmulti: 0,
        oskam: 0,
        oskammulti: 0
    })
    const [picsData, setPicsData] = useState({
        os: 0,
        osmulti: 0, 
        zam: 0,
        zammulti: 0, 
        oxl: 0,
        oxlmulti: 0, 
        fresh: 0,
        freshmulti: 0,
        oskam: 0,
        oskammulti: 0
    })

    const [date, setDate] = useState('')
    const [comment, setComment] = useState('')
 
    const salaryData = { 
    date:date,
    time:'',
    pics:picsData,
    comment: comment,
    }  

    const dateChange = () =>{
        setNow(!now)
        setDate('now')
    }

const inputDateChange = (e) => { 
    if(now === false){
           setDate(format(new Date(e.target.value), "dd.MM.yyyy"))
    }
    else{
        setDate('now')
    }
}

  const picsChange = (e) => {
    setPicsData((prev) => ({
        ...prev,
        [e.target.name]: Number(e.target.value)
    }))
}

const salaryPost = async() => {
    try {
        const res = await postSalary(salaryData)
        console.log(res);
        if(res.status === 400){
            return setSecMessage(res.data.message)
        }
        setPostData(res.data.salary)
        setSecMessage('')
        setIsResult(true)
        setAlert(true)
        setSalaryAlert(true)
        setNow(false)
        setTimeout(() => {
            setSalaryAlert(false)
            setAlert(false)
        }, 3000);
        
    } catch (error) {
       console.error("Произошла ошибка:", error);
       setSecMessage('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
    }
}

const exitResut = () => {
    props.setVisible(false)
    setIsResult(false)
    window.location.reload()
}
  const sectorChange = (e) => {
    setSectorData((prev) => ({
        ...prev,
        [e.target.name]: Number(e.target.value)
    }))
}

    
    const sectorPost = async () =>{
        try {
            const res = await postSector(sectorData)
            if(res.status === 400){
              return  setSecMessage(res.data.message)
            }
            setSecMessage('')
            setSector(true)
            setAlert(true)
            setSectorAddAlert(true)
            props.setVisible(false)
            setTimeout(() => {
            setSectorAddAlert(true)
            setAlert(false)
            }, 3000);
            
        } catch (error) {
         console.error("Произошла ошибка:", error);
         setSecMessage('Что-то пошло не так. Пожалуйста, попробуйте еще раз');
        }
    }
      
      
  return (
     on ? ( isResult ? (<div  onClick={() => exitResut()} className='fixed flex justify-center items-center top-0 bottom-0 right-0 left-0 w-screen h-screen z-10 bg-black/70' >

        <div className='flex justify-center sm:relative sm:top-[2.5%] sm:w-[90%] sm:h-[90%]  lg:flex-wrap flex-col lg:gap-y-4 sm:gap-[0.5%] lg:relative  rounded-lg lg:w-1/2 lg:h-[65%] bg-[#0E0E2C] pt-[1%] sm:pl-[20%] lg:pl-[2%]'  onClick={(e) => e.stopPropagation()}>
        
     <div className="inputBox3">
     <input type="number" value={postData.sectors.os} readOnly />
     <span>Основной</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.osmulti} readOnly />
     <span>ОСМульти</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.oxl} readOnly />
     <span>Охлождёнка</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.oxlmulti} readOnly />
     <span>ОхлМульти</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.zam} readOnly />
     <span>Заморозка</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.zammulti} readOnly />
     <span>ЗамМульти</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.fresh} readOnly />
     <span>Фреш</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.freshmulti} readOnly />
     <span>ФрешМульти</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.oskam} readOnly />
     <span>Оскамера</span>
   </div>
   <div className="inputBox3">
     <input type="number" value={postData.sectors.oskammulti} readOnly />
     <span>КамМульти</span>
     </div>
     <div className='lg:h-[60%] lg:w-[30%] sm:h-[70%] flex flex-col justify-between '>
         <h1 className='text-white lg:text-[120%] sm:text-[90%] border border-secondary rounded-sm lg:w-[120%] lg:p-4 sm:p-2 sm:w-[80%] '>Общая сумма: <span className='font-bold'>{postData.salary} &#8381;</span> </h1>
         <h1 className='text-white lg:text-[120%] sm:text-[90%] border border-secondary rounded-sm lg:w-[100%] lg:p-4 sm:p-2 sm:w-[80%]'>Обший пик: <span className='font-bold'> {postData.summPics} </span> </h1>

     <div onClick={() => exitResut() }  className=" flex justify-center items-center transition ease-out border-none lg:ml-[20%] sm:ml-[20%] rounded-full border font-medium text-white bg-rose-700 cursor-pointer lg:hover:scale-110 lg:active:scale-125 lg:w-[50%] lg:h-[15%] lg:text-base sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[30%] sm:text-[100%] sm:hover:scale-110 sm:active:scale-125">Выход</div>
     </div>

   </div>
   </div>) : (<div onClick={() => {props.setVisible(false)}} className='fixed flex justify-center items-center top-0 bottom-0 right-0 left-0 w-screen h-screen z-10 bg-black/70'>
    { sector ? (<div className='flex justify-center sm:relative sm:top-[2.5%] sm:w-[70%] sm:h-[80%] sm:items-center lg:flex-wrap flex-col lg:gap-y-4 sm:gap-[0.5%] lg:relative  rounded-lg lg:w-1/2 lg:h-[55%] bg-[#0E0E2C] pt-[1%]' onClick={(e) => e.stopPropagation()}>

    <div className="inputBox">
<input  type="number" name='os' onChange={e => picsChange(e)}/>
<span >Основной</span>
</div>

<div className="inputBox">
<input  type="number" name='osmulti' onChange={e => picsChange(e)} />
<span >ОСМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='oxl' onChange={e => picsChange(e)} />
<span >Охлождёнка</span>
</div>
<div className="inputBox">
<input  type="number" name='oxlmulti' onChange={e => picsChange(e)} />
<span >ОхлМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='zam' onChange={e => picsChange(e)} />
<span >Заморозка</span>
</div>
<div className="inputBox">
<input  type="number" name='zammulti' onChange={e => picsChange(e)} />
<span >Заммульти</span>
</div>
<div className="inputBox">
<input  type="number" name='fresh' onChange={e => picsChange(e)} />
<span >Фреш</span>
</div> 
<div className="inputBox">
<input  type="number" name='freshmulti' onChange={e => picsChange(e)} />
<span >ФрешМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='oskam' onChange={e => picsChange(e)} />
<span >Оскамера</span>
</div>
<div className="inputBox">
<input  type="number" name='oskammulti' onChange={e => picsChange(e)} />
<span >КамМульти</span>
</div>
{secMessage && <p className='relative  text-red-700 text-sm'>{secMessage}</p>}
<div className="textBox">
          <textarea  onChange={e => setComment(e.target.value)}/>
          <span >Коммент</span>
      </div>
          <div className="date">
          <input type="date"  onChange={e => inputDateChange(e)} />
          <button className= { (now ? 'bg-indigo-500' : 'bg-primary') + '  justify-center items-center transition ease-out hover:scale-110  active:scale-125  lg:w-[30%] lg:h-[100%] border-none lg:text-base font-medium text-white lg:flex rounded-[3px] sm:h-[95%] sm:w-[20%] sm:text-sm'} onClick={() => dateChange()}>Now</button>
          </div>
      <div onClick={() => salaryPost()} className=" flex justify-between items-center transition ease-out border-none rounded-full border   font-medium text-white bg-primary cursor-pointer lg:hover:scale-110  lg:active:scale-125  lg:w-[130px] lg:h-[46px]  lg:text-base  lg:py-2 lg:px-5 sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[13%]  sm:text-[100%] sm:hover:scale-110  sm:active:scale-125"><img className='lg:w-[30px] lg:h-[28px] sm:w-[30%] sm:h-[75%] ' src={add2}/>Salary</div>
      </div>) :
      (<div className={'flex justify-center sm:relative sm:top-[2.5%] sm:w-[70%] sm:h-[80%] sm:items-center lg:flex-wrap flex-col lg:gap-y-4 sm:gap-[0.5%] lg:relative  rounded-lg lg:w-1/2 lg:h-[55%] bg-[#0E0E2C] pt-[1%]'} onClick={(e) => e.stopPropagation()}>

<div className="inputBox">
<input  type="number" name='os' onChange={e => sectorChange(e)}/>
<span >Основной</span>
</div>

<div className="inputBox">
<input  type="number" name='osmulti' onChange={e => sectorChange(e)} />
<span >ОСМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='oxl' onChange={e => sectorChange(e)} />
<span >Охлождёнка</span>
</div>
<div className="inputBox">
<input  type="number" name='oxlmulti' onChange={e => sectorChange(e)} />
<span >ОхлМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='zam' onChange={e => sectorChange(e)} />
<span >Заморозка</span>
</div>
<div className="inputBox">
<input  type="number" name='zammulti' onChange={e => sectorChange(e)} />
<span >Заммульти</span>
</div>
<div className="inputBox">
<input  type="number" name='fresh' onChange={e => sectorChange(e)} />
<span >Фреш</span>
</div> 
<div className="inputBox">
<input  type="number" name='freshmulti' onChange={e => sectorChange(e)} />
<span >ФрешМульти</span>
</div>
<div className="inputBox">
<input  type="number" name='oskam' onChange={e => sectorChange(e)} />
<span >Оскамера</span>
</div>
<div className="inputBox">
<input  type="number" name='oskammulti' onChange={e => sectorChange(e)} />
<span >КамМульти</span>
</div>
<div className='lg:w-[35%] h-[20%] sm:w-[70%]'>
<h1 className='text-white lg:ml-0 lg:text-[120%] sm:ml-3 sm:text-[100%]'>Введите сумму, которую платит за каждый сектор!</h1>
</div>
<div onClick={() => sectorPost()} className=" flex justify-between items-center transition ease-out border-none rounded-full border   font-medium text-white bg-primary cursor-pointer lg:hover:scale-110  lg:active:scale-125  lg:w-[130px] lg:h-[46px]  lg:text-base  lg:py-2 lg:px-5 sm:w-[40%] sm:mb-3 sm:px-[4%] sm:h-[13%]  sm:text-[100%] sm:hover:scale-110  sm:active:scale-125"><img className='lg:w-[30px] lg:h-[28px] sm:w-[30%] sm:h-[75%] ' src={add2}/>Сектор</div>
{secMessage && <p className='relative  text-red-700 text-sm'>{secMessage}</p>}
</div>)}
</div>) ):("")

  )
}
