import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import { isAlert, isAuth, isAuthAlert, isSecpay, loginMassage, userInfo } from '../../../atom';
import { login } from '../../../api/services/AuthService';
import { getSector } from '../../../api/services/SectorService';
  
export default function Login(props) {
    let log = props.values

    const [auth, setAuth] = useRecoilState(isAuth)
    const [user, setUser] = useRecoilState(userInfo)
    const [loginMessage, setLoginMessage] = useRecoilState(loginMassage)
    const [alert, setAlert] = useRecoilState(isAlert)
    const [alertLog, setAlertLog] = useRecoilState(isAuthAlert)

    const [sector, setSector] = useRecoilState(isSecpay)
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const logins = async() => {
       try {
        const res = await login(email,password)
        
        if(res.status === 400){
            setLoginMessage(res.data.message)
            props.setLogin(true);
        }
        else{
            localStorage.setItem('token', res.data.accessToken)
            setAuth(true)
            setUser(res.data.info)
            setLoginMessage('')
            
            props.setLogin(!props.values);
            setAlert(true)
            setAlertLog(true)
            window.location.reload()

            setTimeout(() => {
                setAlert(false)
                setAlertLog(false)
            }, 3000);
        }
       
       } catch (error) {
        console.error("Произошла ошибка:", error);
        setLoginMessage('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
       }
    }

    useEffect( () => {
        const isSectorPay = async() =>{
          const res = await getSector()
        if(res.data.secPay != undefined || null){
          setSector(true)
        }
        }
        isSectorPay()
      }, [])

    return (
        log ? (<div className='fixed flex justify-center items-end bg-bg  w-full h-screen z-10 top-0 left-0'>
            <div className='w-full lg:h-[91%]  bg-bg flex lg:px-[15%] lg:flex-row lg:justify-between items-center sm:h-[93%] sm:flex-col sm:justify-start '>
                <div className=" flex flex-col  items-center lg:mt-0 lg:h-[60%] sm:items-center sm:h-[30%] sm:mt-[20%]">
                    <h1 className="sm:text-6xl lg:text-8xl px-2 text-white">    
                        Salary <span className="font-bold text-primary">Calc</span>
                    </h1>
                    <p className='text-white flex mt-[9%] lg:text-[100%] sm:text-[80%]'>Возможность рассчитывать и сохранять свою зарплату</p>
                    
                    <p className='text-white text-sm lg:mt-[20%] sm:mt-[10%] lg:text-[100%] sm:text-[70%]' >Если у вас нет учетной записи    <Link className='font-medium text-secondary' to='/registration'>Регистрируйтесь</Link> здесь !</p>
                </div>
                <div className="login">
                {loginMessage && <p className='relative bottom-[5%] text-red-700 text-sm'>{loginMessage}</p>}
                    <div className="loginBox">
                        <input type="text" required='required' onChange={e => setEmail(e.target.value)} />
                        <span>Email</span>
                    </div>
                    <div className="loginBox">
                        <input type="password" required='required' onChange={e => setPassword(e.target.value)} />
                        <span>Пароль</span>
                    </div>
                    <button onClick={() => logins() } className='transform bg-primary sm:w-[30%] sm:h-[12%] lg:w-[20%] lg:h-[10%] rounded-sm text-white font-medium hover:scale-110 active:scale-125'  >Логин</button>
                </div>

            </div>
        </div>) : ("")
    )
}