import React from 'react'
import icons from '../UI/button/icons'
import { Link } from 'react-router-dom'
import { getSalaryAll, getSalaryAllCalc } from '../../atom/salary'
import { salaryGetMonthMessage } from '../../atom'
import { useRecoilState } from 'recoil'
import { getAllSalary } from '../../api/services/SalaryService'

export default function Main() {

   const [sal, setSal] = useRecoilState(getSalaryAll)
   const [calc, setCalc] = useRecoilState(getSalaryAllCalc)
   const [messageGet, setMessageGet] = useRecoilState(salaryGetMonthMessage)

  const allSalaryGet = async() =>{
    try {
      const res = await getAllSalary()
      if(res.status === 400){
        setSal([])
        setCalc(0)
        return setMessageGet(res.data.message)
      }
      setSal(res.data.salary.salary)
      setCalc(res.data.salary.calc)
      setMessageGet('')
    } catch (error) {
      console.error("Произошла ошибка:", error);
      setMessageGet('Вы не авторизованы или Что-то пошло не так. Пожалуйста, попробуйте еще раз');
    }
  }
  return (
    <div className=' bg-bg lg:h-[40%] lg:w-[100%] lg:ml-0 sm:h-[25%]  flex sm:w-[98%] sm:ml-2'>

            <Link className="flex items-center flex-col transition ease-out lg:h-[60%] lg:w-[16%] rounded-[15px] p-2 hover:border-[1px] border-secondary mx-auto mt-[3%] cursor-pointer hover:scale-[1.1] active:scale-[1.2] hover:shadow-3xl sm:h-[85%] sm:w-[30%]" to='/foramonth'>
            <h2 className='flex items-center lg:font-normal lg:text-xl lg:mr-[33%]  text-white sm:text-[80%] sm:mr-[35%] sm:font-bold' > {icons.main_month_icon} <p>Месяц</p></h2>
            <p className='text-white lg:mt-[8%] lg:w-[85%] lg:text-[100%] sm:text-[70%] sm:mt-[5%]'>Здесь вы можете найти ежемесячный вклад за любой месяц.</p>
            </Link>

            <Link className="flex items-center flex-col transition ease-out lg:h-[60%] lg:w-[16%] rounded-[15px] p-2 hover:border-[1px] border-secondary mx-auto mt-[3%] cursor-pointer hover:scale-[1.1] active:scale-[1.2] hover:shadow-3xl sm:h-[85%] sm:w-[30%]" to='/foralifetime'>

            <h2 className='flex items-center lg:font-normal lg:text-xl lg:mr-[30%] text-white sm:text-[80%] sm:mr-[30%] sm:font-bold' > {icons.main_lifetime_icon} <p>Период</p></h2>
            <p className='text-white lg:mt-[8%] lg:w-[85%] lg:text-[100%] sm:text-[70%] sm:mt-[5%]'>Здесь вы можете увидеть зарплату за введенную вами период</p>
            </Link>

            <Link onClick={() => allSalaryGet()} className="flex items-center flex-col transition ease-out lg:h-[60%] lg:w-[16%] rounded-[15px] p-2 hover:border-[1px] border-secondary mx-auto mt-[3%] cursor-pointer hover:scale-[1.1] active:scale-[1.2] hover:shadow-3xl sm:h-[85%] sm:w-[30%]" to='/allsalary'>
            <h2 className='flex items-center lg:w-[90%] sm:w-[120%] lg:font-normal lg:text-xl lg:mr-[0%] text-white sm:text-[80%] sm:mr-[0%] sm:font-bold' > {icons.main_allsalary_icon} <p>Все зарплаты</p></h2>
            <p className='text-white lg:mt-[8%] lg:w-[85%] lg:text-[100%] sm:text-[70%] sm:mt-[5%]'>Отсюда вы можете увидеть свою зарплату за весь период</p>
            </Link>
      </div>
  )
}
