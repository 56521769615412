import React from 'react'
import Navbar from '../components/navbar/Navbar'

export default function Info() {
  return (
    <div className='w-screen h-screen bg-bg'>
      <Navbar/>
      <div className='text-white ml-5 mr-5'>
        <h2 className='text-white text-center mt-2 lg:text-[200%]'>Добро пожаловать в <span className='font-bold'>Salary <span className='text-primary'>Calc</span></span> </h2>
      
        <p className='mt-3 text-center lg:text-[130%] sm:text-[100%]'>Здесь вы можете хранить и вычислять информацию о своей заработной плате и удобным для вас способом искать нужные данные.</p>

        <p className='lg:mt-9 sm:mt-5'> 
        <span className='mr-2'>1.</span> Авторизуйтесь, пожалуйста. Во время авторизации вам нужно будет подтвердить доступ через вашу электронную почту. </p>

        <p className='lg:mt-3 sm:mt-1'> 
        <span className='mr-2'>2.</span> Нажмите кнопку "salary +" на панели навигации и введите цены по секторам. </p>

        <p className='lg:mt-3 sm:mt-1'> 
        <span className='mr-2'>3.</span> Ещё раз нажмите кнопку "salary +" на панели навигации и введите данные о вашей заработной плате. </p>

        <p className='lg:mt-3 sm:mt-1'> 
        <span className='mr-2'>4.</span> Вы можете искать свою зарплату в различных категориях. </p>

        </div>
    </div>
  )
}
