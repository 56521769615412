import React, { useEffect, useState } from "react";
import icons from "../UI/button/icons";
import AddModal from "../UI/modal/AddModal";
import Login from "../UI/modal/Login";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {isAuth, isAlert, salaryGetMessage, isSalaryPutAlert } from "../../atom";
import AlertModal from "../UI/modal/AlertModal";
import { getDateSalary } from "../../api/services/SalaryService";
import { format } from "date-fns";
import { getForDate, getForDateCalc } from "../../atom/salary";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [modal, setModal] = useState(false)
  const [log, setLog] = useState(false)
  const [date, setDate] = useState('')

 const auth = useRecoilValue(isAuth)
 const alert = useRecoilValue(isAlert)

 const [messageGet, setMessageGet] = useRecoilState(salaryGetMessage)
 const [salaryData, setSalaryData] = useRecoilState(getForDate)
 const [salaryCalc, setSalaryCalc] = useRecoilState(getForDateCalc)

 const updateSalAlert = useRecoilValue(isSalaryPutAlert)


 const salGetByDate = async () =>{
  try {
    const res = await getDateSalary(date)
    if(res.status === 400){
      setSalaryData([])
      setSalaryCalc(0)
      return setMessageGet(res.data.message)
    }

    setSalaryData(res.data.salary.salary)
    setSalaryCalc(res.data.salary.calc)
    setMessageGet('')

  } catch (error) {
    console.error("Произошла ошибка:", error);
    setMessageGet('Пожалуйста введите дату, попробуйте еще раз');
  }
 }
 
  const navModalAdd = () =>{
    setModal(true)
    setNav(!nav)
  }
  const navModalLog = () =>{
    setLog(true)
    setNav(!nav)
  }
  
  return ( 
    <div className="max-w-[100%] mx-auto   border-b  border-bg_bt  border-grey flex justify-between items-center lg:px-[7%] py-4 sm:px-4 shadow-sm">
      {alert && <AlertModal/>}
      <AddModal values={modal} setVisible={setModal}/>
      <Login values={log} setLogin={setLog}/>
      <div className="flex items-center justify-center">
        <div onClick={() => setNav(!nav)} className="cursor-pointer">
        <button>{icons.open_btn}</button>
        </div>
        <Link to='/home' className=" sm:text-3xl lg:text-5xl px-2 text-white">
          Salary <span className="font-bold text-primary">Calc</span>
        </Link>
      </div>
   
      <div className="bg-gray-200 rounded-full hover:shadow-3xl flex justify-between items-center px-2 sm:w-[37%] sm:h-[30px] lg:h-[40px] lg:w-[250px]">
        <Link onClick={() => salGetByDate()}  to="/foradate">{icons.search_btn}</Link>
        <input
          className="bg-transparent lg:text-[100%] sm:text-[80%]  lg:w-[75%] sm:w-[85%] focus:outline-none"
          type="date" onChange={e => setDate(format(new Date(e.target.value), 'dd.MM.yyyy'))}/>
      </div>
     
      <div className="lg:ml-14 sm:ml-0">
      {auth ? (<button onClick={() => setModal(true)} >{icons.add_btn}</button>):(<button onClick={() => setLog(true)}>{icons.auth_btn}</button>)} 
      </div>
  
      {nav ? (
        <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
      ) : (
        ""
      )}

      <div
        className={
          nav
            ? "fixed top-0 left-0 w-[300px] h-screen bg-gradient-to-t z-10 duration-300"
            : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
        }
      >
        <div className="flex flex-row-reverse justify-between items-center mt-5 mr-5">
        <button
          onClick={() => setNav(!nav)}
          >{icons.close_btn}</button>
        <h2 className="text-3xl p-4 text-white">
          Salary <span className="font-bold text-primary">Calc</span>
        </h2>
        </div>
        <nav>
          <ul className="flex flex-col p-4">
                <div className=" py-4 ">
                  {auth ? (<li onClick={() => navModalAdd()} className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl mb-[7%] "> {icons.add_icon} <p>Salary</p></li>) : (<li onClick={() => navModalLog()} className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl mb-[7%] "> {icons.auth_icon} <p>Логин</p></li>)}
                
                <li><Link className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl mb-[7%]" to="/foramonth">{icons.month_icon} <p>По месяцам</p> </Link> </li>

                <li><Link to="/foralifetime" className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl mb-[7%]"> {icons.lifetime_icon} <p>За период</p> </Link> </li>

                <li><Link to="/allsalary" className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl mb-[7%] ">{icons.allsalary_icon}<p>Все зарплаты</p> </Link></li>

                <li className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer mb-[7%] w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl "> {icons.util} <Link to='/accountutil'>Конфигурация</Link></li>

                <li className=" transition ease-out active:scale-[1.1] text-xl text-white h-10 flex items-center cursor-pointer  w-[75%] rounded-[3px] mx-auto p-2 hover:border-[1px] hover:border-secondary hover:shadow-3xl "> {icons.information} <Link to='/info'>Инструкция</Link></li>
                </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;