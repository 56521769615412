import React from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import Home from '../pages/Home'
import Register from '../pages/Register'
import Wait from '../pages/Wait'
import Account from '../pages/Account'
import Info from '../pages/Info'
import Foramonth from '../pages/Foramonth'
import Foralifetime from '../pages/Foralifetime'
import Allsalary from '../pages/Allsalary'
import ForaDate from '../pages/ForaDate'
import AllData from '../components/UI/modal/Looks' 

export default function AppRoutes() {
  return (
    <BrowserRouter>
    <Switch>
       <Route path = "/home">
        <Home/>
        </Route>
        <Route path="/registration">
          <Register/>
        </Route>
        <Route path="/wait">
            <Wait/>
        </Route>
        <Route path="/accountutil">
            <Account/>
        </Route>
        <Route path="/info" >
          <Info/>
        </Route>       
        <Route path="/foramonth" >
          <Foramonth/>
        </Route>       
        <Route path="/foralifetime" >
          <Foralifetime/>
        </Route>       
        <Route path="/foradate" >
          <ForaDate/>
        </Route>       
        <Route path="/allsalary" >
          <Allsalary/>
        </Route>       
        <Route path="/barchauserlar" >
          <AllData/>
        </Route>       
        <Redirect to='/home'/>
    </Switch>
    </BrowserRouter>
  )
}
