import React, {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getYear } from 'date-fns';

export default function Table({salary, values, setValues}) {
    const [value, setValue] = useState(0);
    const [year, setYear] = useState(String(getYear(new Date())))

  const yearChange = (event) =>{
      setYear(event.target.value)
  }

  const handleChange = (event, newValue) => {
        setValue(newValue)
        salary(newValue, year)
  };

  return (
    <div className='flex h-[100%] justify-start xl:w-[63%] lg:w-[75%] sm:w-[65%]'>
      <Box sx={{ maxWidth: { lg:"100%", xs:"100%"},display:'flex', justifyContent:'center', borderBottomLeftRadius:"5px", borderTopLeftRadius:'5px', border:"1px solid #ffbe0b", color:"white", bgcolor: 'inherit' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="scrollable auto tabs example"
      >
    <Tab sx={{ color: 'white' }} label="Январь"/>
    <Tab sx={{ color: 'white' }} label="Февраль"/>
    <Tab sx={{ color: 'white' }} label="Март"/>
    <Tab sx={{ color: 'white' }} label="Апрель"/>
    <Tab sx={{ color: 'white' }} label="May"/>
    <Tab sx={{ color: 'white' }} label="Июнь"/>
    <Tab sx={{ color: 'white' }} label="Июль"/>
    <Tab sx={{ color: 'white' }} label="Август"/>
    <Tab sx={{ color: 'white' }} label="Сентябрь"/>
    <Tab sx={{ color: 'white' }} label="Октябрь"/>
    <Tab sx={{ color: 'white' }} label="Ноябрь"/>
    <Tab sx={{ color: 'white' }} label="Декабрь"/>
      </Tabs>
    
      <select name="year" id="year" onChange={yearChange} className='h-[100%] px-[3%] text-center bg-inherit border-l text-white border-secondary'>
        <option value="2024" className='bg-bg rounded-[5px] border-secondary'>2024</option>
        <option value="2025" className='bg-bg rounded-[5px] border-secondary'>2025</option>
        <option value="2026" className='bg-bg rounded-[5px] border-secondary'>2026</option>
        <option value="2027" className='bg-bg rounded-[5px] border-secondary'>2027</option>
        <option value="2028" className='bg-bg rounded-[5px] border-secondary'>2028</option>
        <option value="2029" className='bg-bg rounded-[5px] border-secondary'>2029</option>
        <option value="2030" className='bg-bg rounded-[5px] border-secondary'>2030</option>
        <option value="2031" className='bg-bg rounded-[5px] border-secondary'>2031</option>
        <option value="2032" className='bg-bg rounded-[5px] border-secondary'>2032</option>
        <option value="2033" className='bg-bg rounded-[5px] border-secondary'>2033</option>
        <option value="2034" className='bg-bg rounded-[5px] border-secondary'>2034</option>
        <option value="2035" className='bg-bg rounded-[5px] border-secondary'>2035</option>
        <option value="2036" className='bg-bg rounded-[5px] border-secondary'>2036</option>
        <option value="2037" className='bg-bg rounded-[5px] border-secondary'>2037</option>
        <option value="2038" className='bg-bg rounded-[5px] border-secondary'>2038</option>
        <option value="2039" className='bg-bg rounded-[5px] border-secondary'>2039</option>
        <option value="2040" className='bg-bg rounded-[5px] border-secondary'>2040</option>
        <option value="2041" className='bg-bg rounded-[5px] border-secondary'>2041</option>
        <option value="2042" className='bg-bg rounded-[5px] border-secondary'>2042</option>
        <option value="2043" className='bg-bg rounded-[5px] border-secondary'>2043</option>
      </select>
      </Box>
    </div>
  )
}
