import {atom} from 'recoil'

export const getSectordata = atom({
    key:'getSector',
    default:{}
}) 



export const postSalaryData = atom({
    key:'postSalaryData',
    default:{}
}) 

export const getForDate = atom({
    key:'getForDate',
    default:[]
}) 

export const getForDateCalc = atom({
    key:'getForDateCalc',
    default:0
}) 



export const getForMonth = atom({
    key:'getForMonth',
    default:[]
}) 

export const getForMonthCalc = atom({
    key:'getForMonthCalc',
    default:0
}) 


export const getForLifetime = atom({
    key:'getForLifetime',
    default:[]
}) 
export const getForLifetimeCalc = atom({
    key:'getForLifetimeCalc',
    default:0
}) 


export const getSalaryAll = atom({
    key:'getSalaryAll',
    default:[]
}) 
export const getSalaryAllCalc = atom({
    key:'getSalaryAllCalc',
    default:0
}) 

export const getSevendays = atom({
    key:'getSevendays',
    default:[
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        { x: new Date("0000-00-00"), y:0},
        
      ]
}) 



