import $api from "../http"


export async function getSector(){
    try {
        return await $api.get('/sector/get')

    } catch (error) {
        return error.response
    }
}
export async function postSector(secortData){
    try {
        return await $api.post('/sector/post', secortData)

    } catch (error) {
        return error.response
    }
}
export async function putSector(secortData){
    try {
        return await $api.put('/sector/put', secortData)

    } catch (error) {
        return error.response
    }
}